import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const height = 29;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto',
      height: height,
      display: 'flex',
      borderTop: '1px solid #dc3a70',
      borderBottom: '1px solid #dc3a70',
      borderLeft: '1px solid #dc3a70',
    },
    button: {
      backgroundColor: '#ffffff',
      color: '#dc3a70',
      fontWeight: 'bold',
      fontSize: 14,
      height: '100%',
      textAlign: 'center',
      borderRight: '1px solid #dc3a70',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 1,
    },
    buttonSelected: {
      backgroundColor: '#dc3a70',
      color: '#FFFFFF',
    },
  }),
);

const useInvertStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 'auto',
      height: height,
      display: 'flex',
      borderTop: '1px solid #FFFFFF',
      borderBottom: '1px solid #FFFFFF',
      borderLeft: '1px solid #FFFFFF',
    },
    button: {
      color: '#FFFFFF',
      fontWeight: 'bold',
      fontSize: 14,
      height: '100%',
      backgroundColor: '#dc3a70',
      textAlign: 'center',
      borderRight: '1px solid #FFFFFF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 1,
    },
    buttonSelected: {
      backgroundColor: '#ffffff',
      color: '#dc3a70',
    },
  }),
);

interface Props {
  onSelected: (i: number) => void;
  width: number;
  titles: Array<string>;
  selectedIndex: number;
  appearance?: 'default' | 'invert';
}

export const SwitchButtons = (props: Props) => {
  const defaultClasses = useStyles();
  const invertClasses = useInvertStyles();
  const classes = props.appearance === 'invert' ? invertClasses : defaultClasses;
  return (
    <>
      <div className={classes.container} style={{ width: props.width }}>
        {props.titles.map((title, i) => (
          <div
            key={i}
            className={classNames(classes.button, {
              [classes.buttonSelected]: props.selectedIndex === i,
            })}
            style={{ width: props.width / props.titles.length }}
            onClick={() => props.onSelected(i)}
          >
            <p>{title}</p>
          </div>
        ))}
      </div>
    </>
  );
};
