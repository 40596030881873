// TODO 無限ループSwiperだけ別コンポーネントに切り出し
import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { useSelector } from 'react-redux';

import { ActorCustomRankingPeriodTypeValue, ActorRegularRankingPeriodType, ActorRegularRankingPeriodTypeValue, EmptyRegularRankingSummaryResponse } from '../lib/api';
import { getCustomRankingValueByIndex, getRegularRankingValueByIndex } from '../lib/dataFunctions';
import { RankingDefine, selectRanking } from '../app/rankingSlice';

import { RankingDetail } from './RankingDetail';
import { GAEventValue, reportGA, reportGACustomRanking } from 'lib/googleAnalytics';
import moment from 'moment';

function useSummary(offset: number, define?: RankingDefine) {
  const { customRankingSummary, regularRankingSummary } = useSelector(selectRanking);
  if (!define) return EmptyRegularRankingSummaryResponse;
  if (define.isRegular) {
    return getRegularRankingValueByIndex(
      regularRankingSummary,
      define.periodIndex + offset,
      define.periodType,
      define.rankingType,
    );
  } else {
    return getCustomRankingValueByIndex(customRankingSummary, define.rankingId, define.periodIndex + offset);
  }
}

type Props = {
  handleOnClose: () => void;

  define?: RankingDefine;
  fetchRanking: (define: RankingDefine, periodIndex: number, limit: number, offset: number) => Promise<void>;
  onRegularRankingPeriodTypeChanged: (v: ActorRegularRankingPeriodType) => void;
};

// 無限ループ用に固定で生成する画面数
// 5~推奨
// 3つでは左->右->右などの連続遷移時に、古い状態が一瞬残るケースがある
const SLIDE_NUM = 5;
const DEFAULT_OFFSETS = Array.from({ length: SLIDE_NUM }).map((el, i) => (i > SLIDE_NUM / 2 ? i - SLIDE_NUM : i));

const gaRegularRankingDefines = [
  {
    name: '店舗ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎月,
    gaEvent: GAEventValue.RANKING_TENPO_DETAIL_MONTH,
  },
  {
    name: '店舗ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎年,
    gaEvent: GAEventValue.RANKING_TENPO_DETAIL_YEAR,
  },
  {
    name: 'グループランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎月,
    gaEvent: GAEventValue.RANKING_GROUP_DETAIL_MONTH,
  },
  {
    name: 'グループランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎年,
    gaEvent: GAEventValue.RANKING_GROUP_DETAIL_YEAR,
  },
  {
    name: '同伴ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎月,
    gaEvent: GAEventValue.RANKING_DOUHAN_DETAIL_MONTH,
  },
  {
    name: '同伴ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎年,
    gaEvent: GAEventValue.RANKING_DOUHAN_DETAIL_YEAR,
  },
  {
    name: '指名ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎月,
    gaEvent: GAEventValue.RANKING_SHIMEI_DETAIL_MONTH,
  },
  {
    name: '指名ランキング',
    periodType: ActorRegularRankingPeriodTypeValue.毎年,
    gaEvent: GAEventValue.RANKING_SHIMEI_DETAIL_YEAR,
  },
];

const gaCustomerRankingDefines = {
  [ActorCustomRankingPeriodTypeValue.毎月]: GAEventValue.RANKING_CUSTOM_MONTH,
  [ActorCustomRankingPeriodTypeValue.毎年]: GAEventValue.RANKING_CUSTOM_YEAR,
  [ActorCustomRankingPeriodTypeValue.任意期間]: GAEventValue.RANKING_CUSTOM_PERIOD,
  [ActorCustomRankingPeriodTypeValue.全期間]: GAEventValue.RANKING_CUSTOM_ALL,
}

export const RankingDetailDialog = (props: Props) => {
  const normalizeIndex = useCallback((i) => (SLIDE_NUM + i) % SLIDE_NUM, []);
  const nextIndex = useCallback((i) => normalizeIndex(i + 1), []);
  const prevIndex = useCallback((i) => normalizeIndex(i - 1), []);
  const nowIndex = useCallback((i) => normalizeIndex(i), []);

  const genActives = useCallback((centerIndex: number) => {
    const v = Array.from({ length: SLIDE_NUM }).map((el, i) => false);
    v[normalizeIndex(centerIndex)] = true;
    v[nextIndex(centerIndex)] = true;
    v[prevIndex(centerIndex)] = true;
    v[nextIndex(nextIndex(centerIndex))] = true;
    v[prevIndex(prevIndex(centerIndex))] = true;
    return v;
  }, []);

  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
  // index:0から見た、画面ごとのoffset数 左画面:-1, 右画面:+1
  const [offsets, setOffsets] = useState(DEFAULT_OFFSETS);
  // index:0とその左右の画面がactive
  const [actives, setActives] = useState(genActives(0));

  const resetOffset = useCallback((index: number) => {
    setOffsets((list) => {
      const res = [];
      const v = list[index];
      res[index] = v;
      for (let i = 0; i < SLIDE_NUM; i++) {
        const p = (index + i) % SLIDE_NUM;
        res[p] = v + DEFAULT_OFFSETS[i];
      }
      // console.log('❓resetOffset', index, v, res);
      return res;
    });
  }, []);

  // 画面移動後の状態更新
  const asCenter = (centerIndex: number) => {
    // 指定したindexの画面と、その左右隣の画面をactive(データロードと描画を行う)にする
    setActives(genActives(centerIndex));
    // 指定したindexのoffsetを基準に、各画面のoffset数を再設定する 左画面:-1, 右画面:+1
    resetOffset(centerIndex);
  };

  const clearSwiperState = () => {
    setOffsets(DEFAULT_OFFSETS);
    asCenter(0);
    if (swiper) {
      swiper.allowSlidePrev = true;
      swiper.allowSlideNext = true;
      swiper.slideTo(1, 0, false);
    }
  };

  // ダイアログを閉じる実処理 parentにprops.open更新を依頼
  const onClose = () => {
    props.handleOnClose();
    clearSwiperState();
  };

  // 左画面に遷移
  const onPrevClicked = () => {
    swiper?.slidePrev();
  };

  // 右画面に遷移
  const onNextClicked = () => {
    swiper?.slideNext();
  };

  const realIndex = swiper?.realIndex || 0;

  const prevSummary = useSummary(offsets[prevIndex(realIndex)], props.define);
  const nextSummary = useSummary(offsets[nextIndex(realIndex)], props.define);

  const nowSummary = useSummary(offsets[nowIndex(realIndex)], props.define);

  useEffect(() => {
    if (!props.define || !swiper || swiper.realIndex === undefined) return;
    // state経由で<Swiper>のattribute設定では更新されなかった
    swiper.allowSlidePrev = !!prevSummary?.count;
    swiper.allowSlideNext = !!nextSummary?.count;
  }, [props.define, swiper, prevSummary?.count, nextSummary?.count]);

  const periodTitle2Date = (periodTitle: string) => {
    if (!periodTitle) return;
    const periodDates = periodTitle.split((" ~ "));
    const startDate = moment(periodDates[0], "YYYY.M.D");
    const endDate = periodDates[1].length > 5 ?
      moment(periodDates[1], "YYYY.M.D") :
      moment(startDate.format("YYYY") + "." + periodDates[1].split(".")[0] + "." + periodDates[1].split(".")[1], "YYYY.M.D");
    return [startDate.toDate(), endDate.toDate()]
  }

  useEffect(() => {
    if (!nowSummary?.periodTitle || !props.define) return;
    const periodDates = periodTitle2Date(nowSummary?.periodTitle);
    if (!periodDates) return;
    if (props.define?.isRegular) {
      reportGA(gaRegularRankingDefines.filter(e => e.name === props.define?.name && e.periodType === props.define?.periodType)[0].gaEvent, periodDates[0])
    } else {
      reportGACustomRanking(gaCustomerRankingDefines[props.define?.customRankingPeriodType], props.define?.name + "_" + props.define?.rankingType, periodDates[0], periodDates[1])
    }
  }, [offsets]);

  const onRegularRankingPeriodTypeChanged = (v: ActorRegularRankingPeriodType) => {
    props.onRegularRankingPeriodTypeChanged(v);
    clearSwiperState();
  };

  if (!props.define) return <></>;
  const define = props.define;

  return (
    <Dialog open={!!define} onClose={onClose} maxWidth={false}>
      {/* 左右に無限ループするSwiper */}
      <Swiper
        loop={true}
        allowSlidePrev={false}
        allowSlideNext={false}
        // 明示的な遷移用にインスタンスをstateに保持
        onSwiper={(e) => {
          setSwiper(e);
        }}
        // 右へ遷移開始
        onSlideNextTransitionStart={(e) => {
          e.allowTouchMove = false;
        }}
        // 右へ遷移完了
        // 遷移後のSlide index(0~4)を基準に、activeフラグとindex offsetを設定しなおす
        onSlideNextTransitionEnd={(e) => {
          e.allowTouchMove = true;
          asCenter(e.realIndex);
        }}
        // 左へ遷移開始
        onSlidePrevTransitionStart={(e) => {
          e.allowTouchMove = false;
        }}
        // 左へ遷移完了
        // 遷移後のSlide index(0~4)を基準に、activeフラグとindex offsetを設定しなおす
        onSlidePrevTransitionEnd={(e) => {
          e.allowTouchMove = true;
          asCenter(e.realIndex);
        }}
      >
        {
          // 違和感のない無限ループを実現するため、予めSLIDE_NUMのSlideを確保しておく
          Array.from({ length: SLIDE_NUM }).map((el, index) => (
            <SwiperSlide key={index}>
              <RankingDetail
                define={define}
                fetchRanking={props.fetchRanking}
                periodIndex={define.periodIndex + offsets[index]}
                active={actives[index]} // active:trueでデータのロードと描画を開始する
                onPrevClicked={onPrevClicked}
                onNextClicked={onNextClicked}
                onRegularRankingPeriodTypeChanged={onRegularRankingPeriodTypeChanged}
                onClose={onClose}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </Dialog>
  );
};
