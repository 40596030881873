import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Popover } from '@material-ui/core';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { selectDate, selectSchedule, unSelectDate } from '../app/scheduleSlice';
import { imagePath } from '../lib/pathes';
import { useTenpoPointUnitType } from '../hooks/useTenpoPointUnitType';
import { CalendarDailyScoreTypeValue, シフト依頼状態Value, シフト確定状態Value } from '../lib/api';
import { setDailyScoreDetailDialogParams, setShiftDetailDialogParams } from '../app/applicationSlice';

import { BirthdayCustomerNames } from './BirthdayCustomerNames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    plusMark: {
      position: 'absolute',
      marginTop: 65,
      width: `${100 / 7}%`,
      paddingRight: 9,
      textAlign: 'right',
      fontSize: 9,
      color: 'white',
    },
    r3Cell: {
      height: 106.5,
      width: `${100 / 7}%`,
      borderTop: '1px solid #E8E9E8',
      paddingTop: 11,
      paddingLeft: 7,
      // fontFamily: ['sans-serif'].join(','),
    },
    r3CellHeaderRight: {
      textAlign: 'right',
    },
    r3CellDay: {
      fontSize: 12,
      // fontWeight: theme.typography.fontWeightBold,
    },
    r3CellDaySaturday: {
      color: '#1499CA',
    },
    r3CellDayHoliday: {
      color: theme.palette.primary.main,
    },
    r3CellDayIgnore: {
      color: theme.palette.text.disabled,
    },
    r3CellContent: {
      fontSize: 8,

      fontWeight: theme.typography.fontWeightBold,
      opacity: 0.8,

      // color: theme.palette.text.hint,
      color: '#6D6E6C',

      paddingTop: 3,
      paddingBottom: 6,
      paddingRight: 2,
      wordBreak: 'break-all',
      lineHeight: 1.2,
      overflow: 'hidden',
      height: 106.5 - 17 - 8 - 1,
      // backgroundColor: 'red',
    },
    r3CellContentEntry: {
      marginBottom: 3,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    iconPlus: {
      //      backgroundSize: `${51 / 3}px ${51 / 3}px`,
      backgroundSize: `20px 20px`,
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('calendar_plus2.png')}')`,
    },
    iconPresent: {
      height: 30 / 3,
      width: 28 / 3,
      marginRight: 6,
      verticalAlign: '-7%',
    },
    iconRed: {
      height: 24 / 3,
      width: 24 / 3,
      marginRight: 7,
    },
    presentClickArea: {
      height: 17,
      // backgroundColor: 'red',
    },
    future: {
      color: '#15bae1',
    },
    douhan: {
      color: theme.palette.primary.main,
    },
    simei: {
      color: '#00874C',
    },
    today: {
      backgroundColor: '#fbf4e4',
    },

    dateState0: {
      // フラット
    },
    dateState1: {
      // フラット -> 選択(申請) [紺]
      backgroundColor: '#c1e4e9',
      border: '1px dashed #007bbb',
      paddingLeft: 6,
    },
    dateState2: {
      // 出勤申請中 [赤]
      backgroundColor: '#e83929',
      textShadow: '0px 0px 1px #ffffff',
    },
    dateState3: {
      // 出勤申請中 -> 選択(キャンセル) [ピンク]
      backgroundColor: '#fbc8c2',
      border: '1px dashed #e83929',
      paddingLeft: 6,
      textShadow: '0px 0px 1px #ffffff',
    },
    dateState4: {
      // 出勤確定済み [青]
      backgroundColor: '#007bbb',
      textShadow: '0px 0px 1px #ffffff',
    },
    dateState5: {
      // オフ申請中 [橙・グレーアウト]
      backgroundColor: '#f39800',
      opacity: 0.5,
    },
    dateState6: {
      // 移動申請中(移動元) [橙・グレーアウト]
      backgroundColor: '#f39800',
      opacity: 0.5,
    },
    dateState7: {
      // 移動申請中(移動先) [赤・グレーアウト]
      backgroundColor: '#e83929',
      opacity: 0.5,
    },
  }),
);

type Props = {
  year: number;
  month: number;
  day: number;
  parentMonth: number;
};
const 一覧表示件数 = 6;

export function MonthlyCalendarCell(props: Props) {
  const classes = useStyles();
  const { calendarDateMap, dateSelectMode, selectedDates } = useSelector(selectSchedule);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const unit = useTenpoPointUnitType('p', '円', '');
  const rate = useTenpoPointUnitType(1, 1, 1);

  const m = moment({ years: props.year, months: props.month, days: props.day });
  const date = m.format('YYYYMMDD');
  const dailyData = calendarDateMap[date];
  const d = !dailyData ? null : dailyData.calendarDate;
  const t = new Date();
  const today = props.year === t.getFullYear() && props.month === t.getMonth() && props.day === t.getDate();
  const ignore = props.month !== props.parentMonth;
  const currentDate = moment().format('YYYYMMDD');
  const endDate = moment().add('months', 1).endOf('month').format('YYYYMMDD');
  const dateSelectable = currentDate < date && endDate >= date;
  const cellSelectable = d && !d.done && !ignore;
  const lastOffer = !cellSelectable ? null : dailyData.actorScheduleList.slice(-1)[0];
  const stateSelectable =
    !cellSelectable || !dateSelectable
      ? false
      : !lastOffer ||
        (lastOffer.status === シフト確定状態Value.フラット &&
          (lastOffer.offer === シフト依頼状態Value.フラット ||
            lastOffer.offer === シフト依頼状態Value.キャスト出勤申請中));
  const dateSelected = selectedDates.indexOf(date) !== -1;

  // useCallback(
  const dateSelectHandler = () => {
    if (!d) return;
    if (dateSelectMode) {
      if (stateSelectable) {
        if (dateSelected) {
          dispatch(unSelectDate(date));
        } else {
          dispatch(selectDate(date));
        }
      }
    } else {
      if (d.done) {
        if (!d.scores || d.scores.length === 0) return;
        dispatch(setDailyScoreDetailDialogParams({ year: m.year(), month: m.month(), day: m.date() }));
      } else {
        // if(!dailyData || dailyData.actorScheduleList.length + dailyData.actorVisitorList.length + dailyData.actorEventScheduleList.length === 0) return;
        dispatch(setShiftDetailDialogParams({ year: m.year(), month: m.month(), day: m.date() }));
      }
    }
  };
  //, [d, dateSelectMode, selectable, dateSelected, date]);
  let 出勤確定済 = false;

  let scores: {}[] = [];
  let overflowNum = 0;
  let cellBGClassName = classes.dateState0;
  if (d) {
    出勤確定済 = !!lastOffer && lastOffer.status === シフト確定状態Value.出勤確定済;

    if (dateSelectMode && cellSelectable) {
      // 日付選択モード時のセル背景色を決定
      if (
        !lastOffer ||
        (lastOffer.status === シフト確定状態Value.フラット && lastOffer.offer === シフト依頼状態Value.フラット)
      ) {
        if (dateSelected) {
          cellBGClassName = classes.dateState1;
        }
      } else if (
        lastOffer.offer === シフト依頼状態Value.キャストオフ申請中 ||
        lastOffer.offer === シフト依頼状態Value.スタッフオフ申請中
      ) {
        cellBGClassName = classes.dateState5;
      } else if (
        lastOffer.offer === シフト依頼状態Value.キャスト移動オフ申請中 ||
        lastOffer.offer === シフト依頼状態Value.スタッフ移動オフ申請中
      ) {
        cellBGClassName = classes.dateState6;
      } else if (
        lastOffer.offer === シフト依頼状態Value.キャスト移動出勤申請中 ||
        lastOffer.offer === シフト依頼状態Value.スタッフ移動出勤申請中
      ) {
        cellBGClassName = classes.dateState7;
      } else if (
        lastOffer.offer === シフト依頼状態Value.キャスト出勤申請中 ||
        lastOffer.offer === シフト依頼状態Value.スタッフ出勤申請中
      ) {
        if (dateSelected) {
          cellBGClassName = classes.dateState3;
        } else {
          cellBGClassName = classes.dateState2;
        }
      } else if (lastOffer.status === シフト確定状態Value.出勤確定済) {
        cellBGClassName = classes.dateState4;
      }
    }

    if (d.done) {
      // 締め済み日 - 成績を表示
      if (d.scores) {
        overflowNum = d.scores.length > 一覧表示件数 ? d.scores.length - 一覧表示件数 : 0;
        scores = scores.concat(
          d.scores.slice(0, 一覧表示件数).map((score, i) => {
            return (
              <div
                key={'scores' + i}
                className={classNames(
                  classes.r3CellContentEntry,
                  { [classes.douhan]: score.type === CalendarDailyScoreTypeValue.同伴 },
                  { [classes.simei]: score.type === CalendarDailyScoreTypeValue.指名 },
                )}
              >
                {Math.ceil(score.value / rate).toLocaleString()}
                {unit} {score.name === '' ? 'その他' : score.name}
              </div>
            );
          }),
        );
      }
    } else {
      // 未締め日 - 予定を表示
      const 表示対象件数 = dailyData.actorVisitorList.length + dailyData.actorEventScheduleList.length;
      overflowNum = 表示対象件数 > 一覧表示件数 ? 表示対象件数 - 一覧表示件数 : 0;

      // お客様予定表示を追加
      scores = scores.concat(
        dailyData.actorVisitorList.slice(0, 一覧表示件数).map((actorVisitor, i) => {
          return (
            <div key={'actorVisitorList' + i} className={classNames(classes.r3CellContentEntry, classes.future)}>
              {actorVisitor.title}
              {actorVisitor.douhan ? '同伴' : '予定'}
            </div>
          );
        }),
      );

      // キャストイベント表示を追加
      scores = scores.concat(
        dailyData.actorEventScheduleList
          .slice(0, Math.max(0, 一覧表示件数 - scores.length))
          .map((actorEventSchedule, i) => {
            return (
              <div
                key={'actorEventScheduleList' + i}
                className={classNames(classes.r3CellContentEntry, classes.future)}
              >
                &nbsp;{actorEventSchedule.title}
              </div>
            );
          }),
      );
    }
  }

  return (
    <div
      className={classNames(
        classes.r3Cell,
        { [classes.iconPlus]: overflowNum > 0 && !dateSelectMode },
        { [classes.today]: today },
        cellBGClassName,
      )}
    >
      <Grid container className={classes.presentClickArea}>
        <Grid
          item
          xs={4}
          className={classNames(
            classes.r3CellDay,
            { [classes.r3CellDaySaturday]: m.day() === 6 },
            { [classes.r3CellDayHoliday]: m.day() === 0 || (d && d.isHoliday) },
            { [classes.r3CellDayIgnore]: ignore },
          )}
          onClick={dateSelectHandler}
        >
          {props.day}
        </Grid>
        <Grid item xs={8} className={classes.r3CellHeaderRight}>
          {d && d.birthdayCustomerNames && d.birthdayCustomerNames.length > 0 && (
            <>
              <img
                className={classes.iconPresent}
                src={imagePath('calendar_present.png')}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                alt="誕生日"
              />
              <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                <BirthdayCustomerNames names={d.birthdayCustomerNames} />
              </Popover>
            </>
          )}
          {出勤確定済 ? (
            <img className={classes.iconRed} src={imagePath('calendar_red.png')} alt="出勤日" />
          ) : (
            <div className={classes.iconRed} />
          )}
        </Grid>
      </Grid>
      <div className={classes.plusMark}>{overflowNum > 0 && !dateSelectMode ? '+' + overflowNum : ''}</div>
      <div className={classes.r3CellContent} onClick={dateSelectHandler}>
        {scores}
      </div>
    </div>
  );
}
