import moment from 'moment';

import { RankingDefine } from '../app/rankingSlice';

import {
  ActorBadgeCategory,
  ActorBadgeCategoryValue,
  ActorBadgeType,
  ActorBadgeTypeValue,
  ActorCustomRankingPeriodTypeValue,
  ActorRegularRankingPeriodTypeValue,
  GraphGoalsDto,
  NotificationMessage,
} from './api';

export function getRegularRankingValueByIndex<T>(
  // 相対集計番号 : 集計期間種別 : 集計種別
  data: { [periodIndex: number]: { [periodType: string]: { [rankingType: string]: T } } },
  periodIndex: number,
  periodType: string,
  rankingType: string,
  debugSymbol = '',
): T | undefined {
  if (!data[periodIndex]) return undefined;
  if (!data[periodIndex][periodType]) return undefined;
  if (debugSymbol) console.log('getRegularRankingValueByIndex:', debugSymbol, periodIndex, periodType, rankingType);
  return data[periodIndex][periodType][rankingType];
}

export function getCustomRankingValueByIndex<T>(
  // 集計種別 : 集計番号
  data: { [rankingId: string]: { [periodIndex: number]: T } },
  rankingId: string,
  periodIndex: number,
  debugSymbol = '',
): T | undefined {
  if (!data[rankingId]) return undefined;
  if (debugSymbol) console.log('getCustomRankingValueByIndex:', debugSymbol, rankingId, periodIndex);
  return data[rankingId][periodIndex];
}

export function isNotificationDisabled(notification: NotificationMessage): boolean {
  // enabledでなければ無条件で無効化
  if (!notification.enabled) return true;

  // 回答期限が過ぎていれば無効化
  if (notification.reply_limit !== 0) {
    const replyLimit = moment(notification.reply_limit);
    if (replyLimit.isBefore(moment())) return true;
  }

  return false;
}

export function getBadgeImage(category: ActorBadgeCategory, type: ActorBadgeType): string {
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      switch (type) {
        case ActorBadgeTypeValue.虹:
          return 'badge/badge11.png';
        case ActorBadgeTypeValue.金:
          return 'badge/badge12.png';
        case ActorBadgeTypeValue.銀:
          return 'badge/badge13.png';
        default:
          return 'badge/badge10.png';
      }
    case ActorBadgeCategoryValue.グループポイントランキング:
      switch (type) {
        case ActorBadgeTypeValue.虹:
          return 'badge/badge21.png';
        case ActorBadgeTypeValue.金:
          return 'badge/badge22.png';
        case ActorBadgeTypeValue.銀:
          return 'badge/badge23.png';
        default:
          return 'badge/badge20.png';
      }
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      switch (type) {
        case ActorBadgeTypeValue.虹:
          return 'badge/badge31.png';
        case ActorBadgeTypeValue.金:
          return 'badge/badge32.png';
        case ActorBadgeTypeValue.銀:
          return 'badge/badge33.png';
        default:
          return 'badge/badge30.png';
      }
    case ActorBadgeCategoryValue.ポイント:
      return 'badge/badge41.png';
    case ActorBadgeCategoryValue.サンクスコイン:
      return 'badge/badge51.png';
  }
}

export function getBadgeImageOff(category: ActorBadgeCategory): string {
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      return 'badge/badge10.png';
    case ActorBadgeCategoryValue.グループポイントランキング:
      return 'badge/badge20.png';
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      return 'badge/badge30.png';
    case ActorBadgeCategoryValue.ポイント:
      return 'badge/badge40.png';
    case ActorBadgeCategoryValue.サンクスコイン:
      return 'badge/badge50.png';
  }
}
export function getBadgeImageDefault(category: ActorBadgeCategory): string {
  switch (category) {
    case ActorBadgeCategoryValue.店舗ポイントランキング:
      return 'badge/badge11.png';
    case ActorBadgeCategoryValue.グループポイントランキング:
      return 'badge/badge22.png';
    case ActorBadgeCategoryValue.グループ同伴ランキング:
      return 'badge/badge33.png';
    case ActorBadgeCategoryValue.ポイント:
      return 'badge/badge41.png';
    case ActorBadgeCategoryValue.サンクスコイン:
      return 'badge/badge51.png';
  }
}

export const orderedBadgeCategories = [
  ActorBadgeCategoryValue.店舗ポイントランキング,
  ActorBadgeCategoryValue.グループポイントランキング,
  ActorBadgeCategoryValue.グループ同伴ランキング,
  ActorBadgeCategoryValue.ポイント,
  ActorBadgeCategoryValue.サンクスコイン,
];

export type Help = {
  summary: string;
  title: string;
  body: string;
  image?: string;
};
export const helpList: Help[] = [
  {
    summary: 'ランクについて',
    title: 'ランク',
    body: `獲得したBP（バルセロナポイント）により、ランクが決定されます。ランクによってアプリの仕様・デザインが変わります。
・ランクなし：全員
・サファイア：500BP~
・ルビー：1000BP~
・ダイヤモンド：2000BP~
`,
  },
  {
    summary: 'BP(バルセロナポイント)について',
    title: 'BP(バルセロナポイント)',
    body: `BP（バルセロナポイント）は、キャストさんの普段の頑張りによって獲得が出来るポイントです（普段の売上に紐づくポイントとは別）。

100BP獲得毎にガチャチケット1枚がゲットできます！
    
各条件は以下の通り。
〇初回のみ
・初同伴：100BP
・初指名：100BP
・初目標達成：100BP
・初シフト出し：100BP

〇毎月獲得
・目標達成：100BP
・ランキング上位：10-20BP
　（店舗Pt・グループPt・同伴組数・指名本数）
・自己ベストPt更新：100BP
・無遅刻：20BP
・当欠なし：20BP
・72時間出勤：100BP
・シフト出し：100BP
　（申請期限までに完璧な状態で提出が出来た場合）
`,
  },
  {
    summary: '店舗ポイントランキングバッジについて',
    title: '店舗ポイントランキングバッジ',
    body: `店舗内での月間ポイント/小計ランキングによってバッジ（3種）を獲得できます。
（1~3：レインボー、4~6：金、7~10：銀）
（例えば、1月10位以内だった場合は、2月中にバッジが表示される）
`,
    image: 'badge/badge11.png',
  },
  {
    summary: 'グループポイントランキングバッジについて',
    title: 'グループポイントランキングバッジ',
    body: `グループでの月間ポイントランキングによってバッジ（3種）を獲得できます。
（1~10：レインボー、11~20：金、21~30：銀）
（例えば、1月10位以内だった場合は、2月中にバッジが表示される）
`,
    image: 'badge/badge22.png',
  },
  {
    summary: 'グループ同伴ランキングバッジについて',
    title: 'グループ同伴ランキングバッジ',
    body: `グループでの月間同伴組数ランキングによってバッジ（3種）を獲得できます。
（1~10：レインボー、11~20：金、21~30：銀）
（例えば、1月10位以内だった場合は、2月中にバッジが表示される）
`,
    image: 'badge/badge33.png',
  },
  {
    summary: '自己ベストバッジについて',
    title: '自己ベストバッジ',
    body: `月間でのポイント/小計の自己ベストを更新することでバッジ（1種）を獲得できます。
（例えば、1月に自己ベストを更新した場合は、2月中にバッジが表示される）
`,
    image: 'badge/badge41.png',
  },
  {
    summary: 'サンクスコインバッジについて',
    title: 'サンクスコインバッジ',
    body: `グループでの四半期サンクスコイン獲得枚数ランキングが上位30位以内の場合にバッジ（1種）を獲得できます。
    （例えば、4-6月で上位30位以内だった場合に、7-9月中にバッジが表示される）`,
    image: 'badge/badge51.png',
  },
];

export type CalendarIconHelp = {
  title: string;
  body: string;
  image?: string;
};
export const calendarIconHelpList: CalendarIconHelp[] = [
  {
    title: 'あなたのお客様の誕生日',
    body: '',
    image: 'calendar_icon_help/icon_01.png',
  },
  {
    title: 'シフト提出により出勤が確定した日',
    body: '※過去は表示されません',
    image: 'calendar_icon_help/icon_02.png',
  },
  {
    title: '過去の同伴したお客様のお名前とPt/小計',
    body: '※会員登録されていないお客様は"ゲスト"になります',
    image: 'calendar_icon_help/icon_03.png',
  },
  {
    title: '過去の指名したお客様のお名前とPt/小計',
    body: '※会員登録されていないお客様は"ゲスト"になります',
    image: 'calendar_icon_help/icon_04.png',
  },
  {
    title: `過去の場内指名・その他のお客様の
お名前とPt/小計`,
    body: '※会員登録されていないお客様は"ゲスト"になります',
    image: 'calendar_icon_help/icon_05.png',
  },
  {
    title: `未来の指名・同伴のお客様との
予定やイベント`,
    body: `※本日以降の日付をタップすることで入力可能`,
    image: 'calendar_icon_help/icon_06.png',
  },
];

export function orEmptyGraphGoalsDto(v?: GraphGoalsDto): GraphGoalsDto {
  if (v) return v;

  return {
    成績: 0,
    支給総額: 0,
    店舗内ランキング: 0,
    グループ内ランキング: 0,
    指名本数: 0,
    同伴本数: 0,
    出勤時間: 0,
  };
}

export function isShowAllow(v: RankingDefine): boolean {
  if (v.isRegular) {
    return v.periodType === ActorRegularRankingPeriodTypeValue.毎月;
  } else {
    return (
      v.periodType === ActorCustomRankingPeriodTypeValue.毎月 || v.periodType === ActorCustomRankingPeriodTypeValue.毎年
    );
  }
}
