import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

import { getInnerContentHeight } from '../consts';
import { clearSelectedDate, reloadShiftCalendar, selectSchedule, setDateSelectMode } from '../app/scheduleSlice';
import { CastOfferTypeValue, fetchShiftOffer, シフト依頼状態Value, シフト確定状態Value } from '../lib/api';
import {
  selectApplication,
  setLoading,
  setShiftMoveOfferDialogParams,
  showSuccessAlert,
} from '../app/applicationSlice';
import { AppDispatch } from '../app/store';

import { StandardButton } from './StandardButton';

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20 - 350; // footer分
const r1Height = 100;
const r3Height = 100;
const r4Height = 20;
const r2Height = containerHeight - r1Height - r3Height - r4Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 13,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r2: {
      height: r2Height,
    },
    r2Table: {},
    r3: {
      height: r3Height,
    },
    r3c1: {
      paddingTop: 25,
      paddingLeft: 10,
      textAlign: 'left',
    },
    r3c2: {
      paddingTop: 25,
      paddingRight: 10,
      textAlign: 'right',
    },
    r4: {
      height: r4Height,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 42,
      paddingBottom: 0,
    },
    noBorder: {
      border: 'none',
    },
    tdValue: {
      minWidth: 116,
      paddingRight: 0,
    },
    button: {
      minWidth: 100,
    },
  }),
);

export const ShiftMoveOfferDialog: React.FC = () => {
  const classes = useStyles();
  const { shiftMoveOfferDialogParams, loading } = useSelector(selectApplication);
  const [open, setOpen] = React.useState(false);
  const [toDate, setToDate] = React.useState('');

  const { calendarDateMap } = useSelector(selectSchedule);

  const dispatch: AppDispatch = useDispatch();

  const onClose = () => {
    dispatch(setShiftMoveOfferDialogParams(null));
  };

  useEffect(() => {
    setOpen(!!shiftMoveOfferDialogParams);
  }, [shiftMoveOfferDialogParams]);

  if (!shiftMoveOfferDialogParams) {
    return <></>;
  }

  const offer = {
    date: shiftMoveOfferDialogParams,
    castOfferType: CastOfferTypeValue.移動,
    toDate: '',
  };
  // from now to next month end
  // const toDates = ['20210921', '20210921'];
  const toDates: string[] = [];
  const startDate = moment().add('days', 1);
  const endDate = moment().add('months', 1).endOf('month');

  for (let m = moment(startDate); m.isBefore(endDate); m.add('days', 1)) {
    const date = m.format('YYYYMMDD');
    const dailyData = calendarDateMap[date];
    if (!dailyData) continue;
    const lastOffer = dailyData.actorScheduleList.slice(-1)[0];
    if (
      !lastOffer ||
      (lastOffer.status === シフト確定状態Value.フラット && lastOffer.offer === シフト依頼状態Value.フラット)
    ) {
      toDates.push(date);
    }
  }

  const rows = [offer].map((offer) => {
    return {
      key: 'offer' + offer.date,
      title: moment(offer.date).format('YYYY年M月D日') + ' から',
      value: (
        <FormControl>
          <Select
            defaultValue={toDate}
            onChange={(e) => {
              if (e.target.value) {
                setToDate(e.target.value + '');
              }
            }}
            displayEmpty
          >
            {toDates.map((d) => (
              <MenuItem key={d} value={d}>
                {moment(d).format('YYYY年M月D日')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    };
  });

  // TODO 申請系ロジックまとめる
  const sendOffer = async () => {
    if (loading) {
      return;
    }
    dispatch(setLoading(true));
    // シフト送信
    offer.toDate = toDate;
    const payload = unwrapResult(await dispatch(fetchShiftOffer({ offerList: [offer] })));
    dispatch(showSuccessAlert('申請を送信しました'));
    dispatch(clearSelectedDate());
    dispatch(setDateSelectMode(false));
    onClose();
    reloadShiftCalendar(dispatch, payload.response.updatedDates);
    dispatch(setLoading(false));
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={false}>
      {!!open && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
              <Grid item xs={8} className={classNames(classes.r1C2)}>
                <div>
                  <div className={classes.title}>出勤移動申請</div>
                </div>
              </Grid>
              <Grid item xs={2} className={classNames(classes.r1C3)}>
                <CloseIcon onClick={() => onClose()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
              <Table size='small'>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={!row.key ? row.title : row.key}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classes.noBorder}
                        style={{ paddingLeft: 10, paddingRight: 4 }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classNames(classes.noBorder, classes.tdValue)}
                        style={{ paddingLeft: 4 }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={1} className={classes.r3}></Grid>
          <Grid item xs={5} className={classNames(classes.r3, classes.r3c1)}>
            <StandardButton size='large' onClick={sendOffer} className={classes.button}>
              OK
            </StandardButton>
          </Grid>
          <Grid item xs={5} className={classNames(classes.r3, classes.r3c2)}>
            <StandardButton size='large' onClick={onClose} className={classes.button}>
              戻る
            </StandardButton>
          </Grid>
          <Grid item xs={1} className={classes.r3}></Grid>
          <Grid item xs={12} className={classes.r4}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
