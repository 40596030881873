import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {EffectDetail, effectImageHeight} from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
  },
  rankImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(759),
    height: effectImageHeight(125),
    textAlign: 'center',
  },
  rankImagePadding1:{
    paddingLeft: 4,
    paddingRight: 3,
  },
  rankImagePadding2:{
    paddingLeft: 3,
    paddingRight: 2,
  },
  title: {
    top: effectImageHeight(524),
    fontSize: 22,
    fontWeight: 'bold',
    color: 'rgb(89, 88, 87)',
    width: '76%',
    textAlign: 'center',
    height: effectImageHeight(197),
    lineHeight: 1.3,
    letterSpacing: 1.5,
    wordBreak: 'break-all',
    display: 'grid',
    placeItems: 'center',
  },
  ellipsisBy2Line: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',
  },
}));

export function getEffectSheetType4PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}

// SheetLayoutType.個別ランキング
export function EffectSheetType4(props: Props) {
  const classes = useStyles();
  const rankPathes = String(props.sheet.dto.typeValue).split('').map((s) => assetPath(`img/effect/ranking/ranking/ra${s}.png`));
  rankPathes.push(assetPath('img/effect/ranking/ranking/ra.png'))

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <div className={classNames(classes.image, classes.horizontalCenter, classes.title)}>
      <div className={classes.ellipsisBy2Line}>
        {props.sheet.dto.param1}
      </div>
    </div>
    <div className={classes.rankImageListContainer}>
      {rankPathes.map((p, i) =>
        <img key={i}  className={classNames(
          classes.imageList,
          {[classes.rankImagePadding1]: props.sheet.dto.typeValue < 10},
          {[classes.rankImagePadding2]: props.sheet.dto.typeValue >= 10},
        )} src={p} alt="rank" />
      )}
    </div>
  </div>;
}
