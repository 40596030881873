// TODO 無限ループSwiperだけ別コンポーネントに切り出し
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch } from 'react-redux';

import { MonthlyCalendar } from '../components';
import { MonthlyScoreDetailDialog } from '../components/MonthlyScoreDetailDialog';
import { DailyScoreDetailDialog } from '../components/DailyScoreDetailDialog';
import { ShiftDetailDialog } from '../components/ShiftDetailDialog';

import { VisitorScheduleFormDialog } from 'components/VisitorScheduleFormDialog';
import { requestedCalendarYearMonths, setDateSelectMode } from 'app/scheduleSlice';
import { ShiftOfferConfirmDialog } from 'components/ShiftOfferConfirmDialog';
import { ShiftCancelOfferDialog } from 'components/ShiftCancelOfferDialog';
import { ShiftMoveOfferDialog } from 'components/ShiftMoveOfferDialog';
import { CalendarIconHelpDialog } from 'components/CalendarIconHelpDialog';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

// 無限ループ用に固定で生成する画面数
// 5~推奨
// 3つでは左->右->右などの連続遷移時に、古い状態が一瞬残るケースがある
const SLIDE_NUM = 5;
const DEFAULT_OFFSETS = Array.from({ length: SLIDE_NUM }).map((el, i) => (i > SLIDE_NUM / 2 ? i - SLIDE_NUM : i));

export const CalendarPage: React.FC = () => {
  const dispatch = useDispatch();
  const normalizeIndex = useCallback((i) => (SLIDE_NUM + i) % SLIDE_NUM, []);
  const nextIndex = useCallback((i) => normalizeIndex(i + 1), []);
  const prevIndex = useCallback((i) => normalizeIndex(i - 1), []);

  const genActives = useCallback((centerIndex: number) => {
    const v = Array.from({ length: SLIDE_NUM }).map((el, i) => false);
    v[normalizeIndex(centerIndex)] = true;
    v[nextIndex(centerIndex)] = true;
    v[prevIndex(centerIndex)] = true;
    return v;
  }, []);

  // index:0から見た、画面ごとのoffset数 左画面:-1, 右画面:+1
  const [offsets, setOffsets] = useState(DEFAULT_OFFSETS);
  // index:0とその左右の画面がactive
  const [actives, setActives] = useState(genActives(0));
  const [index, setIndex] = useState(0);

  const resetOffset = useCallback((index: number) => {
    setOffsets((list) => {
      const res = [];
      const v = list[index];
      res[index] = v;
      for (let i = 0; i < SLIDE_NUM; i++) {
        const p = (index + i) % SLIDE_NUM;
        res[p] = v + DEFAULT_OFFSETS[i];
      }
      // console.log('❓resetOffset', index, v, res);
      return res;
    });
  }, []);

  // 画面移動後の状態更新
  const asCenter = useCallback((centerIndex: number) => {
    // 指定したindexの画面と、その左右隣の画面をactive(データロードと描画を行う)にする
    setActives(genActives(centerIndex));
    // 指定したindexのoffsetを基準に、各画面のoffset数を再設定する 左画面:-1, 右画面:+1
    resetOffset(centerIndex);
    dispatch(setDateSelectMode(false));

    setIndex(centerIndex);
  }, []);

  const now = moment();

  useEffect(() => {
    // カレンダー系APIのキャッシュクリア
    requestedCalendarYearMonths.splice(0);
  }, []);

  useEffect(() => {
    const startOfMonth = moment([now.year(), now.month(), 1]).add(offsets[index], 'months');
    reportGA(GAEventValue.CALENDAR_MONTHLY, startOfMonth.toDate())
  }, [index]);

  // 違和感のない無限ループを実現するため、予めSLIDE_NUMのSlideを確保しておく
  const slides = Array.from({ length: SLIDE_NUM }).map((el, index) => {
    return (
      <SwiperSlide key={index}>
        <MonthlyCalendar
          index={index}
          year={now.year()}
          month={now.month()}
          monthOffset={offsets[index]}
          active={actives[index]} // active:trueでデータのロードと描画を開始する
        />
      </SwiperSlide>
    );
  });

  return (
    <>
      <div>
        <Swiper
          loop={true}
          // height={getInnerContentHeight()}
          // 右へ遷移開始
          onSlideNextTransitionStart={(e) => {
            e.allowTouchMove = false;
          }}
          // 右へ遷移完了
          // 遷移後のSlide index(0~4)を基準に、activeフラグとindex offsetを設定しなおす
          onSlideNextTransitionEnd={(e) => {
            e.allowTouchMove = true;
            asCenter(e.realIndex);
          }}
          // 左へ遷移開始
          onSlidePrevTransitionStart={(e) => {
            e.allowTouchMove = false;
          }}
          // 左へ遷移完了
          // 遷移後のSlide index(0~4)を基準に、activeフラグとindex offsetを設定しなおす
          onSlidePrevTransitionEnd={(e) => {
            e.allowTouchMove = true;
            asCenter(e.realIndex);
          }}
        >
          {slides}
        </Swiper>
      </div>
      <MonthlyScoreDetailDialog />
      <DailyScoreDetailDialog />
      <ShiftDetailDialog />
      <VisitorScheduleFormDialog />
      <ShiftOfferConfirmDialog />
      <ShiftCancelOfferDialog />
      <ShiftMoveOfferDialog />
      <CalendarIconHelpDialog />
    </>
  );
};
